import React, { Component } from 'react'
import Lightbox from 'react-image-lightbox'
import Layout from '../../components/layout/layout'
import { Link, graphql } from 'gatsby'
import Img from 'gatsby-image'

import SEO from "../../components/seo"
import CCBreadcrumb from '../../components/layout/breadcrumb'
import SideBarSeOne from "../../components/layout/sidemenu/sidemenu_se_one"

import Alert from 'react-bootstrap/Alert'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Card from 'react-bootstrap/Card'
import Button from 'react-bootstrap/Button'
import ButtonToolbar from 'react-bootstrap/ButtonToolbar'

import ContentHeader from '../../components/page-sections/content-header'
import FocusQuestion from '../../components/page-sections/focus-question'
import Introduction from '../../components/page-sections/introduction'
import ButtonTop from '../../components/buttons/button-top'
import ButtonNext from '../../components/buttons/button-next'
import rowImage from '../../queries/images/row-image'

import 'react-image-lightbox/style.css'

import unit1Icon from '../../images/icons/unit_01_icon.svg'
import planktonic43 from '../../images/student-pages/unit-1/43_planktonic_foraminifera.jpg'
import benthicforaminifera44 from '../../images/student-pages/unit-1/44_benthicforaminifera.jpg'
import spruce46 from '../../images/student-pages/unit-1/46_spruce.jpg'
import co2Records47 from '../../images/student-pages/unit-1/47_co2_records.gif'
import co2Concentrations48 from '../../images/student-pages/unit-1/48_co2_concentrations.gif'


const CoreConnections15 = class extends Component {
  constructor(props) {
    super(props);

    this.state = {
      photoIndexA: 0,
      photoIndexB: 0,
      AisOpen: false,
      BisOpen: false,
    };
  }

  render() {
    const { photoIndexA, AisOpen } = this.state;
    const imageSetA = [
      this.props.data.imageGI1.childImageSharp.fluid.src,
      this.props.data.imageGI2.childImageSharp.fluid.src,
      this.props.data.imageGI3.childImageSharp.fluid.src,
      this.props.data.imageGI4.childImageSharp.fluid.src,
      this.props.data.imageGI5.childImageSharp.fluid.src,
      this.props.data.imageGI6.childImageSharp.fluid.src,
      this.props.data.imageGI7.childImageSharp.fluid.src,
      this.props.data.imageGI8.childImageSharp.fluid.src
    ]
    const { photoIndexB, BisOpen } = this.state;
    const imageSetB = [
      this.props.data.imagePN1.childImageSharp.fluid.src,
      this.props.data.imagePN2.childImageSharp.fluid.src,
      this.props.data.imagePN3.childImageSharp.fluid.src,
      this.props.data.imagePN4.childImageSharp.fluid.src,
      this.props.data.imagePN5.childImageSharp.fluid.src,
      this.props.data.imagePN6.childImageSharp.fluid.src,
      this.props.data.imagePN7.childImageSharp.fluid.src,
      this.props.data.imagePN8.childImageSharp.fluid.src
    ]

    return (
      <Layout location={this.props.location.pathname}>
        <SEO title="Carbon Connections - 1.5 Core Connections" />
        <Container className="mb-5" fluid>
          <Row>
            <Col md="auto">
              <SideBarSeOne location={this.props.location.pathname} />
            </Col>
            <Col>
              <CCBreadcrumb
                pathname={this.props.location.pathname}
                title={'1.5 Core Connections'}
                replace={this.props.replace}
              />
              <Alert
                className="w-100"
                variant="warning"
              >
                BSCS is maintaining this educational resource for archival purposes. Some information may be out of date. We are no longer supporting the resource for classroom use.
              </Alert>
              <Card>
                <Card.Body>

                  <ContentHeader
                    icon={ unit1Icon }
                    iconAlt="Unit 1 icon"
                    studentPageHeader="Unit 1: Carbon &amp; Climate in the Past"
                    unitNumber="1.5"
                    studentPageHeaderSub="Core Connections"
                    sectionA={ <>Key Things You Learned</> }
                    sectionALink="/unit-1/1.5-core-connections/#key-things-learned"
                    sectionB={ <>Field Trips</> }
                    sectionBLink="/unit-1/1.5-core-connections/#field-trips"
                    sectionC={ <>Reflect and Connect</> }
                    sectionCLink="/unit-1/1.5-core-connections/#reflect-and-connect"
                  />

                  <FocusQuestion
                    focusQuestionBodyText={ <>Compared with climate indicators such as CO<sub>2</sub> levels or global ice volume, what other indicators can tell you about Earth's past climate? How do these indicators relate to the carbon cycle?</> }
                  />

                  <Introduction
                    paragraphOne={
                      <>
                        <Row className="d-md-none">
                          <Col>
                            <Img
                              className="rounded img-fluid mb-3"
                              fluid={this.props.data.image39.childImageSharp.fluid}
                              alt="Coral reefs"
                            />
                          </Col>
                        </Row>
                        <Img
                          className="smallImageRight rounded ml-2 mb-1 img-fluid d-none d-md-block"
                          fluid={this.props.data.image39.childImageSharp.fluid}
                          alt="Coral reefs"
                        />

                        <p>
                          In this unit, you have investigated real data. These data are the evidence for variations in Earth's climate in the past. A key part of this climate story is the carbon forms moving in the Earth system. Changes in the climate also connect to the carbon cycle. You will investigate the carbon cycle and climate more in Units 2 and 3.
                        </p>
                      </>
                    }

                    paragraphTwo={
                      <>
                        <p>
                          If someone mentions an ice age, you are likely to think of the great ice sheets in the regions of Earth's poles. The Northern Hemisphere image, for example, emphasizes the changing sizes of continental and sea ice in the Arctic. A broader issue for scientists is testing whether a glacial period is related to the global climate. To find out, you would need to examine other locations on Earth, and perhaps some other proxy records for climate. You will do just that in this lesson as you take two virtual field trips. In general, you will solidify your understandings that:
                        </p>
                        <ul>
                          <li>Data from other locations also show evidence of climate variations on Earth, and that climate connects to the carbon cycle.</li>
                          <li>Climate change in the past has had a pronounced effect on changes in ecosystems.</li>
                          <li>Learning about the carbon cycle and the climate in the Earth system relies on bringing together many disciplines in science.</li>
                        </ul>
                      </>
                    }

                    paragraphThree={
                      <>
                        <Row className="d-md-none">
                          <Col>
                            <Img
                              className="rounded img-fluid mb-3"
                              fluid={this.props.data.image40.childImageSharp.fluid}
                              alt="Northern hemisphere ice coverage"
                            />
                          </Col>
                        </Row>
                        <Img
                          className="largeImageCenter rounded img-fluid d-none d-md-block mb-3"
                          fluid={this.props.data.image40.childImageSharp.fluid}
                          alt="Northern hemisphere ice coverage"
                        />

                        <p>
                          You have investigated several indicators of climate, such as CO<sub>2</sub> levels, ice volume, and Antarctica temperature variations. In this lesson, you will make more connections between carbon and climate with two field trips, where you will analyze some other climate indicators. This is a great way to show what you have learned. Listen to your teacher for what to turn in for this lesson.
                        </p>
                      </>
                    }
                  />

                  <ButtonTop />

                  <Row>
                    <Col>
                      <Card className="mb-4">
                        <Card.Header id="key-things-learned" className="cardHeader">
                          <h2>
                            A. <span className="headerFontBlue">Key Things You Learned in Unit 1</span>
                          </h2>
                        </Card.Header>
                        <Card.Body className="cardBodyBackground">
                          <p>
                            Before you start your activity, you may want to review what you've learned so far.
                          </p>

                          <Row className="d-md-none">
                            <Col>
                              <Img
                                className="rounded img-fluid mb-3"
                                fluid={this.props.data.image41.childImageSharp.fluid}
                                alt="Snow on mountains"
                              />
                            </Col>
                          </Row>
                          <Img
                            className="smallImageRight rounded ml-2 img-fluid d-none d-md-block"
                            fluid={this.props.data.image41.childImageSharp.fluid}
                            alt="Snow on mountains"
                          />

                          <p className="lead"><em>Lesson 1.1</em></p>
                          <ul>
                            <li>Carbon dioxide (CO<sub>2</sub>) is an important molecule that contains carbon.</li>
                            <li>You can use models to demonstrate the transfer of carbon in the Earth system.</li>
                            <li>You can use chemical indicators as color to monitor changes in solutions.</li>
                          </ul>

                          <p className="lead"><em>Lesson 1.2</em></p>
                          <ul>
                            <li>The snow of ice sheets preserve tiny samples of air from Earth's past.</li>
                            <li>Samples of air from ice cores show the relationship between CO<sub>2</sub> levels and temperature for climate in Earth's past.</li>
                          </ul>

                          <p className="lead"><em>Lesson 1.3</em></p>
                          <ul>
                            <li>Forcings lead to responses or changes in systems.</li>
                            <li>Forcings in the Earth system can cause climate to change.</li>
                            <li>Greenhouse gases affect the temperature of Earth's atmosphere.</li>
                            <li>Model and simulations can help you understand forcings in Earth's climate system.</li>
                          </ul>

                          <p className="lead"><em>Lesson 1.4</em></p>
                          <ul>
                            <li>Investigate whether climate signals are global or regional.</li>
                            <li>Analyze other indicators of climate cycles on Earth.</li>
                            <li>Use models of molecules (foam balls) to simulate physical processes on Earth.</li>
                          </ul>
                        </Card.Body>
                      </Card>
                    </Col>
                  </Row>

                  <ButtonTop />

                  <Row>
                    <Col>
                      <Card className="mb-4">
                        <Card.Header id="field-trips" className="cardHeader">
                          <h2>
                            B. <span className="headerFontBlue">Field Trips</span>
                          </h2>
                        </Card.Header>
                        <Card.Body className="cardBodyBackground">
                          <p>
                            In Lesson 1.4, you investigated a record of the size of ice sheets. The <strong>global ice-volume record</strong> showed times of large ice sheets (glacial periods, or <strong>glacials</strong>), and times when ice sheets were small and mostly restricted to Greenland and Antarctica (interglacial periods, or <strong>interglacials</strong>). These occurred at high latitudes and away from the equator. But is there a record of past climates cycles around the equator? If so, what might that evidence look like at the Equator? Let's explore this with a field trip to the Gal&aacute;pagos Islands.
                          </p>

                          <p className="lead">GAL&Aacute;PAGOS ISLANDS, ECUADOR, FIELD TRIP</p>
                          <p>
                            The Gal&aacute;pagos Islands are a cluster of large, volcanic islands in the equatorial Pacific Ocean. The islands are just west of Ecuador, South America. The waters are warm and tropical. Perhaps you have read about these islands in your science class. Animals collected at the Gal&aacute;pagos Islands and South America helped Charles Darwin develop the theory of biological evolution.
                          </p>

                          <p>
                            The Pacific Ocean around the Gal&aacute;pagos Islands abounds with marine life, including foraminifera (forams). The waters have been that way for millions of years. Geologists have obtained long cores of marine sediments that contain forams from a drilling site called ODP (Ocean Drilling Project) Site 677. This helps the geologists determine the &delta;18O of ocean water at that locality in the geologic past.
                          </p>

                          <Button
                            block
                            className="btn-primary mb-4"
                            size="lg"
                            onClick={() => this.setState({ AisOpen: true })}
                          >
                            Open Gal&aacute;pagos Islands Field Trip
                          </Button>

                          <Row className="d-md-none">
                            <Col>
                              <Img
                                className="rounded img-fluid mb-3"
                                fluid={this.props.data.image42.childImageSharp.fluid}
                                alt="Galapagos map"
                              />
                            </Col>
                          </Row>
                          <Img
                            className="largeImageCenter rounded mb-4 img-fluid d-none d-md-block"
                            fluid={this.props.data.image42.childImageSharp.fluid}
                            alt="Galapagos map"
                          />

                          <ol>
                            <li>
                              Launch the Forams Data interactive, which shows two types of forams. Note that the y-axis does not have units. This is because you will be comparing different types of data. To compare data and answer the following questions, you need to move the data up and down. Use the interactive to answer these questions:
                                <ol type="a">
                                <li>Overall, do you think the data from the two different types of forums tell a similar or different story about the &delta;<sup>18</sup>O pattern for ocean water?</li>
                                <li>In the Forums Data interactive, look at the records for atmospheric CO<sub>2</sub> and temperature from Antarctica. You studied these in Lesson 1.2. How do these data compare with &delta;<sup>18</sup>O signal for ocean water at the Gal&aacute;pagos Islands?</li>
                                <li>
                                  Compare another record, the global ice volume signal, with the &delta;<sup>18</sup>O record from ocean water. Do these records correlate? Does one cause the other? <br />
                                  <em>Click to select a record, then use mouse to drag the curve up or down.</em>
                                </li>
                                <li>Summarize what you found from the Gal&aacute;pagos Islands data. Do foram &delta;<sup>18</sup>O values from warm, tropical oceans preserve a record of global climate patterns? Explain why you would, or would not, expect this.</li>
                              </ol>
                            </li>
                          </ol>
                        </Card.Body>
                      </Card>
                    </Col>
                  </Row>

                  <ButtonTop />

                  <Row>
                    <Col>
                      <Card className="mb-4">
                        <Card.Header id="reflect-and-connect" className="cardHeader">
                          <h2>
                            <span className="headerFontBlue">Explore More: Two Types of Forams</span>
                          </h2>
                        </Card.Header>
                        <Card.Body className="cardBodyBackground">
                          <p className="lead">Two Types of Forams</p>

                          <Row className="d-md-none">
                            <Col>
                              <img
                                className="rounded img-fluid mb-3"
                                src={ planktonic43 }
                                alt="planktonic foraminifera"
                              />
                            </Col>
                          </Row>
                          <img
                            className="smallImageRight rounded ml-4 mb-1 img-fluid d-none d-md-block"
                            src={ planktonic43 }
                            alt="planktonic foraminifera"
                          />

                          <p>
                            The &delta;<sup>18</sup>O data for forams from the Gal&aacute;pagos Islands come from two varieties; each is a key part of marine ecosystems. One type is <strong>planktonic foraminifera</strong>. Planktonic forams thrive in the photic zone of oceans. This is the upper 100-200 meters, where light penetrates and photosynthesis occurs. In this zone, <strong>primary producers</strong> (such as diatoms and single-celled algae) transfer carbon in the oceans to organisms of marine food webs. They do this by photosynthesis, the process of using CO<sub>2</sub> in the oceans to create their tissues. You will learn more about photosynthesis in Unit 2.
                          </p>

                          <p>
                            Planktonic foraminifera consume these primary producers. In other words, the planktonic forams are <strong>primary consumers</strong> because they eat the producers. When the planktonic forams die, or when they themselves are eaten, their carbon-bearing remains sink to the seafloor. Recall that this part is the calcium carbonate test. This is all that remains after the organic tissues of the foram either are digested or decompose in the ocean. The image shows an example of a carbonate test that became part of the seafloor sediments.
                          </p>

                          <Row className="d-md-none">
                            <Col>
                              <img
                                className="rounded img-fluid mb-3"
                                src={ benthicforaminifera44 }
                                alt="benthicforaminifera"
                              />
                            </Col>
                          </Row>
                          <img
                            className="smallImageLeft mr-3 img-fluid d-none d-md-block"
                            src={ benthicforaminifera44 }
                            alt="benthicforaminifera"
                          />

                          <p>
                            Another type is <strong>benthicforaminifera</strong>. Benthic forams live on the seafloor. By comparison, other types of benthic organisms also live at the bottom of lake ecosystems. Because temperatures are much colder in deeper ocean waters, benthic forams have a different &delta;18O values than planktonic foraminifera. The key question is whether the pattern of &delta;18O curves is similar or different for benthic and planktonic forams. What do you see in the data from ODP Site 677 at the Gal&aacute;pagos Islands? Use the interactive to compare these foram data with some other global data sets that you have studied. That's question 2 from the previous section.
                          </p>
                        </Card.Body>
                      </Card>
                    </Col>
                  </Row>

                  <ButtonTop />

                  <Row>
                    <Col>
                      <Card className="mb-4">
                        <Card.Header id="field-trips" className="cardHeader">
                          <h2>
                            <span className="headerFontBlue">Pacific Northwest Field Trips</span>
                          </h2>
                        </Card.Header>
                        <Card.Body className="cardBodyBackground">
                          <Row className="d-md-none">
                            <Col>
                              <Img
                                className="rounded img-fluid mb-3"
                                fluid={this.props.data.image45.childImageSharp.fluid}
                                alt="Pacific Northwest map"
                              />
                            </Col>
                          </Row>
                          <Img
                            className="verticalImageRight rounded ml-2 img-fluid d-none d-md-block"
                            fluid={this.props.data.image45.childImageSharp.fluid}
                            alt="Pacific Northwest map"
                          />

                          <p>
                            Let's take another field trip! This time, we're visiting the open waters of the Pacific Ocean, offshore of northern California and Oregon. This region is the <strong>Pacific Northwest</strong>. It is at mid-latitudes, about halfway between the equator and the North Pole. On this field trip, you will examine data from a drill core to see whether the sediment keeps a record of climate variation. You will also investigate what the climate indicators tell you about ecosystems and the carbon cycle in the Pacific Northwest. This will help you answer your focus questions.
                          </p>

                          <p>
                            The cores are from the deep-sea muds at ODP Site 1020. There, large rivers have flushed sediments from the rugged mountains of the Cascades down to the Pacific Ocean. Along with mud and silt, these rivers also contain the pollen of plants and trees that live in the mountains.
                          </p>

                          <p>
                            By looking at the types of pollen in the core, you can infer the kinds of vegetation that lived in the mountains. This, in turn, gives us key clues about climate. When the climate in the Pacific Northwest was cold and dry, the pollen in the rivers show that the mountains had more plants that lived in a cold, dry climate. In contrast, when the climate in the Pacific Northwest was warm and wet, the pollen in the rivers indicate plant varieties that live in that kind of climate. You will use the data from ODP Site 1020 to see what plants these are. The Pollen Journey video will show you a bit more about rivers and pollen in the Pacific Northwest.
                          </p>

                          <p>
                            The ocean waters above ODP Site 1020 region are rich with marine life, including forams. Geologists have measured the &delta;<sup>18</sup>O of those forams. In addition, another chemical indicator from the forams tells geologists the temperature of the ocean water where the forams lived. You will continue to use these data to make inferences about the climate and ecosystems in the Pacific Northwest.
                          </p>

                          <Button
                            block
                            className="btn-primary mb-4"
                            size="lg"
                            onClick={() => this.setState({ BisOpen: true })}
                          >
                            Open Pacific Northwest Field Trip
                          </Button>

                          <div className="embed-container mb-3">
                            <iframe title="pollen journey" className="videoSetting" src="https://player.vimeo.com/video/307779899" frameBorder="0" webkitallowfullscreen={toString('true')} mozallowfullscreen={toString('true')} allowFullScreen></iframe>
                          </div>

                          <p>
                            On your field trip to this site, use the geologic data in the interactive to answer the questions below.
                          </p>

                          <div className="col-12">
                            <iframe
                              className="embed-container w-100"
                              src="https://media.bscs.org/carbonconnections/interactives/pollen-indicator/pollen-indicator.html"
                              title="Pollen indicator interactive"
                            />
                          </div>
                          <p>If you are unable to see the interactive, click <a href="https://media.bscs.org/carbonconnections/interactives/pollen-indicator/pollen-indicator.html" target="_blank" rel="noopener noreferrer">here&nbsp;<i className="fas fa-external-link-alt"></i></a> to open it in a new tab.</p>

                          <ol type="a" start="3">
                            <li>
                              Open the Pollen Indicator interactive. You should see these data from sediments at ODP Site 1020:
                              <ul>
                                <li>Sea surface temperature (SST)</li>
                                <li>&delta;<sup>18</sup>O data for forams at Site 1020</li>
                                <li>Species of plants, given as percent of all pollen in a core sample</li>
                                <li>An indicator of warm, wet climate (on average)</li>
                                <li>An indicator of cold, dry climate (on average)</li>
                                <em>Click to select a record, then use mouse to drag the curve up or down.</em>
                              </ul>
                            </li>
                            <li>
                              Compare the &delta;<sup>18</sup>O of forams between ODP Site 1020 and the Gal&aacute;pagos (Site 677). Do forams from Site 1020 preserve a record of global climate? Compare this record with other records, such as ODP Site 677, CO<sub>2</sub> levels, or global ice volume. Use specific years from the graph as your evidence. <br />
                              <em>Hint: Use the sea surface temperature from Site 1020 to help support your case.</em>
                            </li>
                            <li>
                              Next, analyze the pollen records. Starting with redwood (<em>Sequoia</em>), use these steps to determine whether they were more abundant in the ecosystem in glacial or interglacial periods.
                              <ol type="a">
                                <li>Large peaks show times when pollen was common in rivers, and thus common in sediments on the seafloor. A flat line indicates few, or no, redwoods. How many major peaks do you see?</li>
                                <li>Select another global climate record, such as global ice volume or atmospheric CO<sub>2</sub>. Use this record to determine whether redwoods were more common during glacial or interglacial periods.</li>
                                <li>Two curves are shown for times when the average climate was warm and wet, or when it was cold and dry. Compare the redwood pollen record to each. In which climate do redwoods thrive? Is this a glacial or interglacial period? </li>
                              </ol>
                            </li>
                            <li>
                              Look at the four remaining pollen records: Oak, Sage, Gramma, and Alder. Use the following steps to determine whether each type of pollen was more abundant when the average regional climate was warm and wet, or cold and dry.
                              <ol type="a">
                                <li>Make a Regional Climate table to record your evidence.</li>
                                <li>See the example results entered for redwood, which has a check mark for relatively warm, wet regional climate. In the column to the right, note the evidence that supports the selection of a warm, wet regional climate. You will enter more evidence like this from climate records of other plant species.</li>
                                <li>Continue to fill in your table with results for oak, sage, gramma, and alder.</li>
                              </ol>
                            </li>
                          </ol>

                          <table className="table table-bordered table-hover table-responsive-md studentTable">
                            <thead>
                              <tr className="text-center">
                                <th className="table-active" scope="col">Pollen type</th>
                                <th className="table-active" scope="col" colSpan="2">Regional climate</th>
                                <th className="table-active" scope="col">Evidence for the average regional climate</th>
                              </tr>
                              <tr className="text-center">
                                <th className="table-active" scope="col">&nbsp;</th>
                                <th className="table-active" scope="col">Warm, wet</th>
                                <th className="table-active" scope="col">Cold, dry</th>
                                <th className="table-active" scope="col">&nbsp;</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>Redwood</td>
                                <td className="text-center">X</td>
                                <td></td>
                                <td>Peaks in redwood pollen correlate with peaks in atmospheric CO<sub>2</sub> levels, low global ice volume, and interglacial periods like today.</td>
                              </tr>
                              <tr>
                                <td>Oak</td>
                                <td></td>
                                <td></td>
                                <td></td>
                              </tr>
                              <tr>
                                <td>Sage</td>
                                <td></td>
                                <td></td>
                                <td></td>
                              </tr>
                              <tr>
                                <td>Gramma</td>
                                <td></td>
                                <td></td>
                                <td></td>
                              </tr>
                              <tr>
                                <td>Alder</td>
                                <td></td>
                                <td></td>
                                <td></td>
                              </tr>
                            </tbody>
                          </table>

                          <ol type="a" start="7">
                            <li>Select just the redwood data from Site 1020 and the foram &delta;<sup>18</sup>O signal data from Site 677. Look at the relationship between these records: Each indicates glacial and interglacial <strong>epochs</strong>. Would you say that the relationship is a <em>cause</em> or a <em>correlation</em>? Explain your logic.</li>
                            <li>
                              To summarize, think about the record of glacial-interglacial cycles and the plants in ecosystems in the Pacific Northwest. In Lesson 1.2, you saw that interglacials have a lot of carbon stored as biomass on land, while glacials had much less vegetation on land. Are ecosystem patterns in the Pacific Northwest consistent with this, or different? <br />
                              <em>Hint: Think about the size and height of plants that represent the two climates.</em>
                            </li>
                          </ol>
                        </Card.Body>
                      </Card>
                    </Col>
                  </Row>

                  <ButtonTop />

                  <Row>
                    <Col>
                      <Card className="mb-4">
                        <Card.Header id="reflect-and-connect" className="cardHeader">
                          <h2>
                            <span className="headerFontBlue">Explore More: Spruce Bonus</span>
                          </h2>
                        </Card.Header>
                        <Card.Body className="cardBodyBackground">
                          <p className="lead">Spruce Bonus</p>

                          <Row className="d-md-none">
                            <Col>
                              <img
                                className="rounded img-fluid mb-3"
                                src={ spruce46 }
                                alt="spruce"
                              />
                            </Col>
                          </Row>
                          <img
                            className="smallImageRight rounded ml-2 img-fluid d-none d-md-block"
                            src={ spruce46 }
                            alt="spruce"
                          />

                          <p>
                            Spruce is another interesting plant in the Pacific Northwest. See if you can tell whether spruce is relatively more common during glacial or interglacials in the Pacific Northwest. If you are able to graph data in your class, do these steps:
                          </p>

                          <ol type="a">
                            <li>Click the Download NASA 1020 Picea Data button below.</li>
                            <li>Graph spruce pollen (&percnt; of total) on the y-axis against sediment age (years) on the x-axis.</li>
                            <li>Use a record such as global ice volume or atmospheric CO<sub>2</sub> to identify whether, <em>on average</em>, the peaks correspond with glacials or interglacials.</li>
                          </ol> &nbsp;&nbsp;

                          <a
                            href="http://media.bscs.org/carbonconnections/images/student_edition/02_curriculum/unit1/documents/nasa_1020_picea_data.xls"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <Button>
                              Download NASA 1020 Picea Data&nbsp;&nbsp;<i className="fas fa-cloud-download-alt"></i>
                            </Button>
                          </a>
                        </Card.Body>
                      </Card>
                    </Col>
                  </Row>

                  <ButtonTop />

                  <Row>
                    <Col>
                      <Card className="mb-4">
                        <Card.Header id="reflect-and-connect" className="cardHeader">
                          <h2>
                            C. <span className="headerFontBlue">Reflect and Connect</span>
                          </h2>
                        </Card.Header>
                        <Card.Body className="cardBodyBackground">
                          <p>
                            At the end of Unit 1, it is a good time to reflect on some key concepts. Your teacher will tell you which of the following questions to complete and when they are due.
                          </p>

                          <ol type="a" start="9">
                            <li>Think about your process of learning in Unit 1. What way, or ways, helped you learn the lessons?</li>
                            <li>What is your current thinking about how the carbon cycle is related to climate? List two examples from Unit 1 to support your answer.</li>
                            <li>In this unit, you have studied natural climate variations on Earth. This included variations between glacial and interglacial cycles. How do you think that studying Earth's past climate can help you understand present variations in climate?</li>
                            <li>
                              Some key fields of science are life science, earth science, chemistry, physics, and engineering.
                              <ol type="a">
                                <li>In which field(s) of science would you place the concept of the carbon cycle?</li>
                                <li>In which field(s) of science would you place the study of climate science? Why?</li>
                                <li>What is another topic or issue in society that relies on more than one of the disciplines above (for example, food production and agriculture)?</li>
                              </ol>
                            </li>
                          </ol>

                          <Row className="d-md-none">
                            <Col>
                              <img
                                className="rounded img-fluid mb-3"
                                src={ co2Records47 }
                                alt="Carbon dioxide record, 140,000 years ago to today"
                              />
                            </Col>
                          </Row>
                          <img
                            className="largeImageCenter rounded img-fluid d-none d-md-block mb-4"
                            src={ co2Records47 }
                            alt="Carbon dioxide record, 140,000 years ago to today"
                          />

                          <ol type="a" start="13">
                            <li>
                              You studied data from an ice core in the Antarctic ice sheet (EPICA). It compared CO<sub>2</sub> levels and temperature there for the past 650,000 years. The Carbon Dioxide Record graph shows the last 140,000 years of those data. It also includes CO<sub>2</sub> levels measured from Law Dome (Antarctica; see <Link to="/unit-1/1.5-core-connections/#co2Concentrations48">Carbon Dioxide Concentrations graph</Link>) and Mauna Loa (Hawaii).
                              <ol type="a">
                                <li>Describe CO<sub>2</sub> levels in recent times. What are the levels?</li>
                                <li>How do current CO<sub>2</sub> levels compare with prior patterns of glacial and interglacial cycles?</li>
                                <li>Given current CO<sub>2</sub> levels, what do you predict could be a future outcome for Earth's climate? What other evidence in this unit supports your reasoning?</li>
                              </ol>
                            </li>
                          </ol>

                          <Row className="d-md-none">
                            <Col>
                              <img
                                id="co2Concentrations48"
                                className="rounded img-fluid mb-3"
                                src={ co2Concentrations48 }
                                alt="Carbon dioxide concentrations, last 2,000 years"
                              />
                            </Col>
                          </Row>
                          <img
                            id="co2Concentrations48"
                            className="largeImageCenter rounded mb-4 img-fluid d-none d-md-block"
                            src={ co2Concentrations48 }
                            alt="Carbon dioxide concentrations, last 2,000 years"
                          />

                          <ol type="a" start="14">
                            <li>
                              The Carbon Dioxide Concentrations graph shows CO<sub>2</sub> levels in the atmosphere the past 2,000 years. Data to about 1900 are from the Law Dome, Antarctica.
                              <ol type="a">
                                <li>What is the main feature you see in these data for CO<sub>2</sub> levels? What year is the feature found?</li>
                                <li>List your ideas for how to explain feature(s) that you see. It's OK if you don't know the answer, but start thinking about some possibilities.</li>
                              </ol>
                            </li>
                          </ol>
                        </Card.Body>
                      </Card>
                    </Col>
                  </Row>

                  <ButtonTop />

                  <ButtonNext
                    ButtonText="Unit 2: Current Carbon and Climate"
                    NextLink="/unit-2/2.0-overview"
                  />

                  {AisOpen && (
                    <Lightbox
                      mainSrc={imageSetA[photoIndexA]}
                      nextSrc={imageSetA[(photoIndexA + 1) % imageSetA.length]}
                      prevSrc={imageSetA[(photoIndexA + imageSetA.length - 1) % imageSetA.length]}
                      onCloseRequest={() => this.setState({ AisOpen: false })}
                      onMovePrevRequest={() =>
                        this.setState({
                          photoIndexA: (photoIndexA + imageSetA.length - 1) % imageSetA.length,
                        })
                      }
                      onMoveNextRequest={() =>
                        this.setState({
                          photoIndexA: (photoIndexA + 1) % imageSetA.length,
                        })
                      }
                    />
                  )}

                  {BisOpen && (
                    <Lightbox
                      mainSrc={imageSetB[photoIndexB]}
                      nextSrc={imageSetB[(photoIndexB + 1) % imageSetB.length]}
                      prevSrc={imageSetB[(photoIndexB + imageSetB.length - 1) % imageSetB.length]}
                      onCloseRequest={() => this.setState({ BisOpen: false })}
                      onMovePrevRequest={() =>
                        this.setState({
                          photoIndexB: (photoIndexB + imageSetB.length - 1) % imageSetB.length,
                        })
                      }
                      onMoveNextRequest={() =>
                        this.setState({
                          photoIndexB: (photoIndexB + 1) % imageSetB.length,
                        })
                      }
                    />
                  )}

                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </Layout>
    )
  }
}

export default CoreConnections15


export const query = graphql`
  query {
    image39: file(relativePath: { eq: "student-pages/unit-1/39_coral_reefs.jpg" }) {
      ...rowImage
    }
    image40: file(relativePath: { eq: "student-pages/unit-1/40_northern_hemisphere.jpg" }) {
      ...rowImage
    }
    image41: file(relativePath: { eq: "student-pages/unit-1/41_mountain_snow.jpg" }) {
      ...rowImage
    }
    image42: file(relativePath: { eq: "student-pages/unit-1/42_galapagos_map.jpg" }) {
      ...rowImage
    }
    image45: file(relativePath: { eq: "student-pages/unit-1/45_map_northwest.jpg" }) {
      ...rowImage
    }
    imageGI1: file(relativePath: { eq: "student-pages/unit-1/15-galapagos-trip/01_galapagos_islands.jpg" }) {
      ...rowImage
    }
    imageGI2: file(relativePath: { eq: "student-pages/unit-1/15-galapagos-trip/02_galapagos_islands_2.jpg" }) {
      ...rowImage
    }
    imageGI3: file(relativePath: { eq: "student-pages/unit-1/15-galapagos-trip/03_galapagos_islands_3.jpg" }) {
      ...rowImage
    }
    imageGI4: file(relativePath: { eq: "student-pages/unit-1/15-galapagos-trip/04_galapagos_landscape.jpg" }) {
      ...rowImage
    }
    imageGI5: file(relativePath: { eq: "student-pages/unit-1/15-galapagos-trip/05_blue_footed_booby.jpg" }) {
      ...rowImage
    }
    imageGI6: file(relativePath: { eq: "student-pages/unit-1/15-galapagos-trip/06_darwins_finch.jpg" }) {
      ...rowImage
    }
    imageGI7: file(relativePath: { eq: "student-pages/unit-1/15-galapagos-trip/07_marine_iguanas.jpg" }) {
      ...rowImage
    }
    imageGI8: file(relativePath: { eq: "student-pages/unit-1/15-galapagos-trip/08_paddle_cactus.jpg" }) {
      ...rowImage
    }
    imagePN1: file(relativePath: { eq: "student-pages/unit-1/15-pacific-northwest-trip/01_pacific_northwest_people_walking.jpg" }) {
      ...rowImage
    }
    imagePN2: file(relativePath: { eq: "student-pages/unit-1/15-pacific-northwest-trip/02_pacific_northwest_mountain.jpg" }) {
      ...rowImage
    }
    imagePN3: file(relativePath: { eq: "student-pages/unit-1/15-pacific-northwest-trip/03_pacific_northwest_gorge_winter.jpg" }) {
      ...rowImage
    }
    imagePN4: file(relativePath: { eq: "student-pages/unit-1/15-pacific-northwest-trip/04_pacific_northwest_foggy_forest.jpg" }) {
      ...rowImage
    }
    imagePN5: file(relativePath: { eq: "student-pages/unit-1/15-pacific-northwest-trip/05_pacific_northwest_tree.jpg" }) {
      ...rowImage
    }
    imagePN6: file(relativePath: { eq: "student-pages/unit-1/15-pacific-northwest-trip/06_pacific_northwest_waterfall.jpg" }) {
      ...rowImage
    }
    imagePN7: file(relativePath: { eq: "student-pages/unit-1/15-pacific-northwest-trip/07_pacific_northwest_land.jpg" }) {
      ...rowImage
    }
    imagePN8: file(relativePath: { eq: "student-pages/unit-1/15-pacific-northwest-trip/08_pacific_northwest_ocean.jpg" }) {
      ...rowImage
    }
  }
`
